.App {
  text-align: center;
  padding-bottom: 20px;
}
html {
  background-color: #22324e;
  color: white;
}

.App-logo {
  height: 40vmin;
}
.App-title {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.App-icon {
  align-self: baseline;
  height: 70px;
  margin-top: 6px;
  margin-right: 20px;
}
.App-text {
  color: white;
  max-width: 540px;
  margin: auto;
  padding-left: 20px;
  padding-right: 20px;
  text-align: left;
}
.App-badge {
  margin-bottom: 20px;
}
.App-screen_shot {
  max-width: 150px;
  margin: 10px;
}
.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}
.App-footer {
  margin-top: 40px;
}
